const getDefaultState = () => {
  return {
    menuCollapsed: false
  }
}

const state = getDefaultState()

const getters = {
  isMenuCollapsed (state) {
    return !!state.menuCollapsed
  }
}

const mutations = {
  setMenuCollapsedToggle (state) {
    state.menuCollapsed = !state.menuCollapsed
  }
}

export default {
  state,
  getters,
  mutations,
  namespaced: true
}
