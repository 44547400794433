import Vue from 'vue'
import Vuex from 'vuex'
import admin from './modules/admin'
import app from './modules/app'
import auth from './modules/auth'
import benefits from '@/store/modules/benefits'
import createPersistedState from 'vuex-persistedstate'
import features from '@/store/modules/features'
import helpers from './modules/helpers'
import idea from './modules/idea'
import ideaCollection from './modules/ideaCollection'
import itemsView from './modules/itemsView'
import selectIdea from './modules/selectIdea'
import theme from '@/store/modules/theme'
import ui from './modules/ui'
import user from './modules/user'

Vue.use(Vuex)

// noinspection JSUnresolvedFunction
export default new Vuex.Store({
  modules: {
    admin,
    app,
    idea,
    ideaCollection,
    itemsView,
    selectIdea,
    theme,
    helpers,
    user,
    ui,
    auth,
    features,
    benefits
  },

  plugins: [
    createPersistedState({
      key: 'persisted_state',
      paths: [
        'user',
        'idea',
        'itemsView',
        'ideaCollection',
        'app',
        'theme'
      ]
    })
  ],

  actions: {
    resetState ({ commit }) {
      commit('idea/resetState')
      commit('idea/ideaPlan/resetState')
      commit('ideaCollection/resetState')
      commit('itemsView/resetState')
      commit('selectIdea/resetState')
      commit('user/resetState')
      commit('ui/resetState')
      commit('admin/resetState')
    }
  },

  strict: process.env.NODE_ENV !== 'production'
})
