import UserSubscriptionStatusEnum from '@/constants/UserSubscriptionStatusEnum'

function intercomPaymentUserBoot (user, intercom) {
  const selectedIdeaAndFromTemplate = user.selectedIdea && user.selectedIdea.fromTemplate
  const isSelectedIdea = !!user.selectedIdea

  const data = {
    user_id: user.id,
    user_hash: user.hash,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    IdeaCount: user.ideas.length,
    SharedIdeaCount: user.sharedIdeas.length,
    Template: user.selectedIdea ? user.selectedIdea.fromTemplate : false,
    BusinessGuideCount: user.selectedIdea ? Object.values(user.selectedIdea.completedSteps).reduce((a, item) => a + item, 0) : 0,
    IdeaPlanCount: user.selectedIdea ? (user.selectedIdea.ideaPlans.length > 0 ? user.selectedIdea.ideaPlans[0].notesCount : 0) : 0,
    AppSumoUser: user.isAppSumoUser ?? false,
    language_override: localStorage.getItem('lang'),
    TemplateId: selectedIdeaAndFromTemplate ? user.selectedIdea.catalogueIdeaTemplate.id : null,
    TemplateName: selectedIdeaAndFromTemplate ? user.selectedIdea.catalogueIdeaTemplate.catalogueIdeaName : null,
    TemplateLang: selectedIdeaAndFromTemplate ? user.selectedIdea.catalogueIdeaTemplate.lang : null,
    AICredit: user.aiRequests ? user.aiRequests.amount : null,
    AIUsed: user.aiRequests ? user.aiRequests.amountSpent : null,
    IdeaCategoryId: isSelectedIdea ? user.selectedIdea.catalogueCategory.id : null,
    IdeaCategory: isSelectedIdea ? user.selectedIdea.catalogueCategory.name : null,
    IdeaTypeId: isSelectedIdea ? user.selectedIdea.catalogueIdea.id : null,
    IdeaType: isSelectedIdea ? user.selectedIdea.catalogueIdea.name : null
  }

  if (!user.subscription) {
    intercom.boot({
      ...data,
      PlanName: null,
      PlanType: 'Trial',
      PlanExpiry: user.freeTrial,
      MaxIdeas: 1,
      ActiveSubscription: false,
      AccountValid: user.freeTrial
    })

    return
  }

  intercom.boot({
    ...data,
    PlanName: user.subscription.plan,
    PlanType: user.subscription.type.charAt(0).toUpperCase() + user.subscription.type.slice(1),
    PlanExpiry: user.subscription.nextBillDate ? user.subscription.nextBillDate.date : null,
    MaxIdeas: user.subscription.ideas,
    ActiveSubscription: user.subscription.status === UserSubscriptionStatusEnum.ACTIVE,
    AccountValid: user.subscription.nextBillDate ? user.subscription.nextBillDate.date : null
  })
}

export { intercomPaymentUserBoot }
